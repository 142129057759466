/* eslint-disable no-eval */

/** React components  */
import React, { useState, useEffect, useRef } from 'react';

/** react-query */
import { useQuery, useQueryClient, useMutation } from 'react-query';

/** To custom event handling */
import EventEmitter from 'react-native-eventemitter';

/** To animating views easely */
import { Animated } from 'react-animated-css';

import quitDismissIcon from '../../../assets/img/gantt/quit-header-config.png';
import { openNotification, getFlattenActivities } from '../../../utils';

/** import helpers */
import { getTasksWithRoute, getCncList } from './cncs.helper';
import {
  getCncTypes,
  getResponsables,
  getLastLevelActivities,
  getListCncs,
  updateCnc,
  deleteCnc,
  getWeekCommitments
} from '../weeklyPlan.helper';

import { resizeNameTaskColumn } from '../../../utils/lookahead-common';

import CustomPaginator from '../../../components/CustomPaginator';

import AnimatedSortable from '../../../components/AnimatedSortable';
/** Build components from antd css framework */
import { Row, Col, Modal, Button, Tooltip, Popconfirm, Icon } from 'antd';
import { Spin, Empty, Popover } from 'antd';

import CncForm from '../../../components/WeeklyPlan/Cnc/CncForm';

import useWindowDimensions from '../../../hooks/useWindowDimensions';
/** Services */
import { taskService, cncService } from '../../../services';

/** Plain text CSS file (react pretty features) */
import './index.css';

import { socket } from '../../../services/socket.service';

/** Redux */
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { cncActions } from '../../../redux/actions/cncActions';

/** JSON with table distribution */
import { tableMetadata } from './table.layout';

/** Single table with activity as header and tasks as childs */
import CncActivity from '../../../components/WeeklyPlan/Cnc/CncActivity';
import CncOtherGroup from '../../../components/WeeklyPlan/Cnc/CncOtherGroup';

/** Header with filters options component */
// import CncFilterHeader from '../../../components/WeeklyPlan/Cnc/CncFilterHeader';
import LookaheadFilterHeader from '../../../components/LookaheadFilterHeader';

/** Function to clone objects on JS */
import cloneDeep from 'lodash/cloneDeep';
import differenceBy from 'lodash/differenceBy';
import { capitalize } from 'lodash';

/** Function to keep states replacing specific elements */
import update from 'immutability-helper';
import { firstBy } from 'thenby';

/** import icons from antd framework */
import { LoadingOutlined } from '@ant-design/icons';

/** Massive actions icons */
import deleteMassive from '../../../assets/img/massive/cnc/delete.svg';
import priorityMassive from '../../../assets/img/massive/cnc/priority.svg';
import statusMassive from '../../../assets/img/massive/cnc/status.svg';
import typeMassive from '../../../assets/img/massive/cnc/type.svg';
import { withTranslation } from 'react-i18next';
import { TimerManagerSingleton } from '../../../utils/timerManager';

/**
 * This view shows activities from last level.
 * Then user can through a filter change range date showed.
 * Then, user can add on eagger data model tasks, associating them with the activity ID as a super parent.
 * @param {*} props
 */
function CncView(props) {
  const timerManager = TimerManagerSingleton.getInstance();
  const userLang = navigator.language || navigator.userLanguage;

  const leanStatusColVisibility = useRef(null);
  /** Project state from redux */
  const projectState = useSelector((state) => state.projectState);
  const projectSelected = projectState.allProjects.find(
    (el) => el.id == projectState.projectSelected
  );
  const cncState = useSelector((state) => state.cncState);
  const dispatch = useDispatch();
  const [isTree, setIsTree] = useState({ value: true });

  const { permission, t } = props;
  /** handle custom paginator */
  const [currentPage, setCurrentPage] = useState(0);

  /** handle Form Add item */
  const [visibleForm, setVisibleForm] = useState({ value: false, tasks: [] });

  /** Activities to handle table loading */
  const [activities, setActivities] = useState([]);
  const [activitiesReport, setActivitiesReport] = useState([]);

  /** Array with users that belongs to the same sector that this master plan */
  const [toSelectResponsables, setToSelectResponsables] = useState([]);

  /** Flag to know when component is on loading step */
  const [isLoading, setLoading] = useState(true);

  /** Object to handle start and end dates to show last level activities from master plan  */
  const [dateRange, setDateRange] = useState({ start: '', end: '' });

  /** Handle modal for img */
  const [imgModal, setImgModal] = useState();

  /** Handle tasks */
  const [taskList, setTaskList] = useState([]);
  const [visibleImg, setVisibleImg] = useState(false);

  /** Variable to view and his components to handle table manipulation by user */
  const [tableConfig, setTableConfig] = useState([]);
  /** handle state popover */
  const [massivePriorityPop, setMassivePriorityPop] = useState({
    visible: false
  });
  const [massiveTypePop, setMassiveTypePop] = useState({ visible: false });
  const [massiveStatusPop, setMassiveStatusPop] = useState({ visible: false });

  /** Handle Types */
  const [cncTypes, setCncTypes] = useState([]);

  const groupByDefault = { criteria: 'no-group', sort: 'asc' };

  /** Object to handle start and end dates to show last level activities from master plan  */
  const [groupBy, setGroupBy] = useState(groupByDefault);
  const [orderBy, setOrderBy] = useState({
    value: [{ name: 'id', order_by: 'asc' }]
  });
  const [filterBy, setFilterBy] = useState({ value: [] });
  const [resizing, setResizing] = useState(false);
  const [massiveSelection, setMassiveSelection] = useState([]);
  const { height, width } = useWindowDimensions();
  const [scrollStates, setScrollStates] = useState({});
  const [massiveOnProcess, setMassiveOnProcess] = useState(false);

  const [dataNoGroup, setDataNoGroup] = useState({}); /** state initial data */
  const [tableUpdated, setTableUpdated] = useState(false);
  const [allWeekCommitments, setAllWeekCommitments] = useState([]);
  const [allTaskCommitments, setAllTaskCommitments] = useState([]);
  const [allActivitiesCommitments, setAllActivitiesCommitments] = useState([]);

  /** Full width feature */
  const [totalTablesWidth, setTotalTablesWidth] = useState(0);
  let paginatorRef = useRef();

  // Queries && Mutations
  const queryClient = useQueryClient();
  /** get users by sector */
  const cncTypesRq = useQuery(['cncTypesRq', projectSelected], () =>
    getCncTypes(projectSelected)
  );

  /** get users by sector */
  const responsablesRq = useQuery(
    ['responsablesRq', projectState.sectorSelected],
    async () => await getResponsables({ id: projectState.sectorSelected })
  );

  /** get cncs by sector */
  const cncListRq = useQuery(['cncListRq', projectState.sectorSelected], () =>
    getListCncs(projectState.sectorSelected)
  );

  /** get week commitments */
  const weekCommitmentsListRq = useQuery(
    ['weekCommitmentsListRq', projectState.sectorSelected],
    () => getWeekCommitments(projectState.sectorSelected)
  );

  /** Querys Mutators */
  /**
   * This function edit object from table & update cache
   * @param {*} data Object {} Object data to pass on to mutation side effects
   */
  const mutationEdit = useMutation(
    async ({ cnc }) => await updateCnc({ cnc }),
    {
      onMutate: (variables) => {
        const previousValue = queryClient.getQueryData([
          'cncListRq',
          projectState.sectorSelected
        ]);
        const updatedValue = previousValue.cncs;
        const index = updatedValue.findIndex(
          (el) => el.id === variables.cnc.id
        );
        if (index !== -1) {
          updatedValue[index] = {
            ...updatedValue[index],
            ...variables.cnc
          };
          queryClient.setQueryData('cncListRq', {
            cncs: updatedValue
          });
        }
      },
      onSettled: () => {
        queryClient.removeQueries(['cncListRq', projectState.sectorSelected]);
      }
    }
  );

  /**
   * This function delete object from table & update cache
   * @param {*} data Object {} Object data to pass on to mutation side effects
   */
  const mutationDelete = useMutation(
    async ({ cncId, activity }) => await deleteCnc({ cncId }),
    {
      onMutate: (variables) => {
        const oldItems = queryClient.getQueryData([
          'cncListRq',
          projectState.sectorSelected
        ]);
        const newData = oldItems.cncs.filter(
          (item) => item.id !== variables.cncId
        );

        /** delete from dataNoGroup */
        setDataNoGroup([
          {
            name: t('weekly_plan_cnc.title'),
            tasks: newData
          }
        ]);
        queryClient.setQueryData('cncListRq', {
          cncs: newData
        });
        return () => {};
      },
      onSettled: () => {
        queryClient.removeQueries(['cncListRq', projectState.sectorSelected]);
      }
    }
  );

  /** Effect to load translation to table declaration file */
  useEffect(() => {
    const loadTableTranslation = setTimeout(() => {
      const copyOfTableData = [...tableMetadata];
      copyOfTableData.map((column) => {
        if (
          column.data_type.includes('/icon') ||
          column.data_type.includes('/string')
        ) {
          column.label = t('tables.weeklyplan.cncs.' + column.name + '.label');
          column.from_values.map((option) => {
            option.label = t(
              'tables.weeklyplan.cncs.' +
                column.name +
                '.options.' +
                option.value
            );
          });
        } else {
          column.label = t('tables.weeklyplan.cncs.' + column.name);
        }
      });
      setTableConfig(copyOfTableData);
      setTableUpdated(true);
      if (tableUpdated && cncListRq?.data?.cncs) {
        const listTmp = getCncList(cncListRq);
        let cncs = [];
        setToSelectResponsables(responsablesRq.data.users);
        /** add user to cnc */
        cncs = listTmp.cncs.map((el) => {
          const findUser = responsablesRq.data.users.find(
            (user) => user.id === el.userId
          );
          return {
            ...el,
            users: [findUser]
          };
        });
        setActivities([
          {
            name: t('weekly_plan_cnc.title'),
            tasks: cncs
          }
        ]);
      }
    }, 500);
  }, []);

  /** This effect allows to load initial lookahead range, and reacts to change on sectorSelected from redux */
  useEffect(() => {
    /** Loads activities with their tasks */
    if (responsablesRq.isSuccess && responsablesRq.data?.users) {
      if (cncListRq.isSuccess && cncListRq.data?.cncs) {
        if (tableConfig.length) {
          getData();
        }
      }
    }
  }, [projectState.sectorSelected, tableUpdated]);

  /** effect to load all task commitments */
  useEffect(() => {
    if (
      weekCommitmentsListRq.isSuccess &&
      weekCommitmentsListRq.data.weekcommitments
    ) {
      const weekData = weekCommitmentsListRq.data.weekcommitments;
      allTaskCommitmentsFn(weekData);
    }
  }, [weekCommitmentsListRq.data]);

  const allTaskCommitmentsFn = async (weeks) => {
    setAllWeekCommitments(weeks);
    const allTasks = [];
    weeks.map((el) => {
      if (el.taskcommitments.length) {
        el.taskcommitments.map((tc) => {
          allTasks.push(tc);
        });
      }
    });
    const tasks = await getTasksWithRoute(
      allTasks,
      projectState.sectorSelected,
      taskService
    );
    setAllTaskCommitments(tasks?.tasks || []);
    setAllActivitiesCommitments(tasks?.activities || []);
  };

  const getData = async () => {
    const data = await getCncs();
    setDataNoGroup(data);
  };

  useEffect(() => {
    dispatch(cncActions.notifyLookaheadUpdateGroup());

    const notifyLookaheadUpdateOrderCallback = () => {
      dispatch(cncActions.notifyLookaheadUpdateOrder());
    };
    timerManager.registerAutoTimeout(
      notifyLookaheadUpdateOrderCallback,
      50,
      'notifyLookaheadUpdateOrder2'
    );

    const notifyLookaheadUpdateFilterCallback = () => {
      dispatch(cncActions.notifyLookaheadUpdateFilter());
    };
    timerManager.registerAutoTimeout(
      notifyLookaheadUpdateFilterCallback,
      100,
      'notifyLookaheadUpdateFilter2'
    );
  }, [cncState.notifyChange]);

  useEffect(() => {
    if (!resizing) {
      let tablesWidth = 0;
      tableConfig.map((el) => {
        if (el.visible) {
          tablesWidth += el.width;
        }
      });
      setTotalTablesWidth(tablesWidth);
    }
  }, [tableConfig]);

  useEffect(() => {
    if (paginatorRef.current) {
      paginatorRef.current.resetAfterColumnIndex(0);
    }
  }, [totalTablesWidth]);

  /**
   * This function handles with resize of cols inside the table from dragging resize  from header titles
   * @param {*} width Updated width catched from the event
   * @param {*} colMetadata Single column metadata that were resized
   */
  const onDivResizeHandler = (width, colMetadata) => {
    if (colMetadata.doubleClicked) {
      colMetadata.width = width;
      colMetadata.doubleClicked = false;
      updateTableMetadata();
    }
  };

  /**
   * This function handles the click, and the unclick from user when the resize is catched
   * @param {*} col Single column metadata that is going to be resized
   * @param {*} val Boolean, true for start resizing, false for end resizing
   */
  const onDivResizeDoubleClicked = (col, val) => {
    col.doubleClicked = val;
    // setResizing(val)
    // updateTableMetadata()
  };

  /** Similar to did mount */
  useEffect(() => {
    const callback = (data) => {
      data.route(props.history);
    };
    EventEmitter.on('changeMainRoute', callback);
    return () => {
      EventEmitter.removeListener('changeMainRoute', callback);
    };
  }, []);

  const updateAsyncCnc = (cnc) => {
    mutationEdit.mutate({
      cnc,
      sector: projectState.sectorSelected,
      queryClient
    });
    socket.emit('cnc_update', {
      sector: projectState.sectorSelected,
      cnc: cnc
    });
  };

  const modifyGroupBy = async (newGroupBy, data = activities) => {
    if (newGroupBy.criteria == 'no-group') {
      // data = await getCncs(false)
      data = dataNoGroup;
      data.sort(
        firstBy('id', { direction: newGroupBy.sort, ignoreCase: true })
      );
    } else {
      let col = tableConfig.filter((el) => el.name == newGroupBy.criteria);
      if (col.length != 0) {
        col = col[0];
        if (
          newGroupBy.criteria == groupBy.criteria &&
          newGroupBy.sort != groupBy.sort
        ) {
          data.sort(
            firstBy('value', { direction: newGroupBy.sort, ignoreCase: true })
          );
        } else {
          if (isTree.value || newGroupBy.criteria != groupBy.criteria) {
            // data = await getCncs(false)
            data = dataNoGroup;
            isTree.value = false;
          }
          setMassiveSelection([]);
          let newData = [];
          if (col.from_values == 'week') {
            const tmpData = data[0].tasks.map((el) => {
              let ret = `${el.year}-${el.week}`;
              if (el.week < 10) {
                ret = `${el.year}-0${el.week}`;
              }
              return ret;
            });
            /** get unique array of dates like 2020-51 */
            const uniq = [...new Set(tmpData)];
            const uniqSort = uniq.sort();
            newData = uniqSort.map((el) => ({
              value: el,
              label: el,
              img: '',
              tasks: []
            }));
          } else if (col.from_values == 'toSelectResponsables') {
            newData = toSelectResponsables.map((el) => ({
              value: el[col.el_to_extract_from],
              name: el.name,
              img: el.image,
              tasks: []
            }));
          } else if (col.from_values.length) {
            newData = col.from_values.map((el) => ({
              value: el.value,
              tasks: [],
              label: el.label
            }));
          }
          data.map((constraints) => {
            constraints.tasks.map((constraint) => {
              newData.map((singleParent) => {
                /** Save object reference */
                if (col.name == 'responsables' || col.name == 'user') {
                  const users =
                    col.name != 'user'
                      ? constraint[col.name]
                      : constraint[col.ref];
                  if (users) return;
                  users.map((responsable) => {
                    if (
                      responsable[col.el_to_extract_from] == singleParent.value
                    ) {
                      singleParent.tasks.push(constraint);
                    }
                  });
                } else {
                  if (constraint[col.name] == singleParent.value) {
                    singleParent.tasks.push(constraint);
                  }
                  if (col.from_values == 'week') {
                    if (
                      `${constraint.year}-${constraint.week}` ===
                      singleParent.value
                    ) {
                      singleParent.tasks.push(constraint);
                    }
                  }
                }
              });
            });
          });
          data = newData;
          data.sort(
            firstBy('value', { direction: newGroupBy.sort, ignoreCase: true })
          );
        }
      }
    }
    updateRender(data);
    setGroupBy(newGroupBy);
    setIsTree({ ...isTree });
  };
  /**
   * This function get cnc to use them at DOM
   */
  const getCncs = async (reLoad = true) => {
    /** set load to true */
    if (reLoad) {
      setLoading(true);
    }
    setMassiveSelection([]);
    setCurrentPage(0);

    const listTmp = getCncList(cncListRq);
    /** get tasks */
    if (listTmp) {
      const cncTasks = cloneDeep(listTmp.cncTasksArr);
      /** get tasks added to cnc */
      const tasks = await getTasksWithRoute(
        cncTasks,
        projectState.sectorSelected,
        taskService
      );
      setTaskList(tasks?.tasks || []);
    }

    let cncs = [];
    setToSelectResponsables(responsablesRq.data.users);
    /** add user to cnc */
    cncs = listTmp.cncs.map((el) => {
      const findUser = responsablesRq.data.users.find(
        (user) => user.id === el.userId
      );
      return {
        ...el,
        users: [findUser]
      };
    });
    /** If data were fetch succesfully, we pass this data to component states */
    if (reLoad) {
      setActivities([
        {
          name: t('weekly_plan_cnc.title'),
          tasks: cncs
        }
      ]);
    }

    if (cncTypesRq.isSuccess && cncTypesRq.data) {
      const typeFrom = tableConfig.find((col) => col.name == 'cncTypeId');
      typeFrom.from_values.splice(0, typeFrom.from_values.length);
      cncTypesRq.data.cnctypes.map((type, index) => {
        typeFrom.from_values.push({
          value: type.id,
          label: type.name,
          weigth: index + 1
        });
      });
      setTableConfig([...tableConfig]);
      setCncTypes(cncTypesRq.data.cnctypes);
    }

    if (reLoad) {
      setLoading(false);
    }
    return [
      {
        // name: "CNC's",
        name: t('weekly_plan_cnc.title'),
        tasks: cncs
      }
    ];
  };

  /**
   * This function updates the state of table metadata
   * @param {*} tMetadata Object with same attr than tableConfig to replace at view state
   */
  const updateTableMetadata = (tMetadata = tableConfig) => {
    let metadata = cloneDeep(tMetadata);
    metadata = resizeNameTaskColumn(metadata, width);

    setTableConfig(metadata);
  };

  /**
   * This functions update the state of activities at view
   */
  const updateRender = (ac = activities) => {
    setActivities([...ac]);
    // eslint-disable-next-line prefer-const
    let data = dataNoGroup;
    data.sort(firstBy('id', { direction: 'asc', ignoreCase: true }));
    data[0].childRended = data[0].tasks.length;
    setActivitiesReport([...data]);
  };

  /**
   * This function is used by datepickers to save new valeus to component state
   * @param {*} value Moment date object
   * @param {*} dateString String date
   */
  const changeDateState = async (value, dateString) => {
    if (dateString[0] != '' && dateString != '') {
      await setDateRange((prev) => {
        prev.start = dateString[0];
        prev.end = dateString[1];
        return prev;
      });
      await setGroupBy((prev) => {
        prev = { criteria: 'activity', sort: 'asc' };
        return prev;
      });
      getCncs();
    } else {
      setActivities([]);
    }
  };

  /**
   * This function executes massiveSelectionHandler then sets the selection state to
   * handle massive actions like deleting, changing responsable, etc.
   * @param {*} selection Initial selection
   */
  const recursivelySelection = (selection) => {
    /** Temporal array to save added task */
    const toAdd = [];

    /** Temporal array to save deleted task */
    const toDelete = [];

    /** Execution of recursively selection */
    massiveSelectionHandler(selection, null, toAdd, toDelete);

    /** Setting state of massive actions */
    setMassiveSelection((prev) => {
      /* Take off elements of massive actions state */
      const newState = differenceBy(prev, toDelete, 'id');

      /** Then we check those elements that arent at the state and add them */
      toAdd.map((el) => {
        const doesExist = newState.find((single) => el.id == single.id);
        if (!doesExist && !el.hide) {
          newState.push(el);
        }
      });

      prev = cloneDeep(newState);
      return prev;
    });
  };

  /**
   * This function handles with massive selection tasks
   * @param {*} selection Element to change selecton status by it active attribute
   * @param {*} flag This saves the parent selected status to match with their children
   * @param {*} toAdd Array with tasks to add to state array with massive selection
   * @param {*} toDelete Array with tasks to delete from state array with massive selection
   */
  const massiveSelectionHandler = (
    selection,
    flag = null,
    toAdd = [],
    toDelete = []
  ) => {
    /** Define array of children (activity or task) */
    const childs = selection.id ? null : selection.tasks;

    /** Defines first flag value from calling this function without flag */
    if (flag == null) {
      flag = !selection.active;
      selection.active = !selection.active;
    }

    /** Matchs the values to dont change already setted correct value */
    if (flag != selection.active) {
      selection.active = !selection.active;
    }

    /** Defines if the current selection must be deleted or added to state array of massive selection */
    if (selection.id) {
      if (selection.active) {
        toAdd.push(selection);
      } else if (!selection.active) {
        toDelete.push(selection);
      }
    }

    /** Recursively repetition for children */
    if (childs && childs.length) {
      childs.map((el) => {
        massiveSelectionHandler(el, flag, toAdd, toDelete);
      });
    }
  };

  /**
   * This function handles the drag n drop movements for change cols disposition
   * @param {*} oldIndex Index from the array that is dragged
   * @param {*} newIndex Index from the element that the dragged el is going to be moved
   */
  const moveCard = async ({ oldIndex, newIndex }) => {
    const dragCard = tableConfig[oldIndex];

    const a = update(tableConfig, {
      $splice: [
        [oldIndex, 1],
        [newIndex, 0, dragCard]
      ]
    });
    updateTableMetadata(a);
  };

  const [array, setArray] = useState([]);
  useEffect(() => {
    setArray([renderGeneralHeader(), ...activities]);
  }, [activities, isLoading, tableConfig]);

  /**
   * This function render the table with activities and their tasks
   */
  const renderTable = () => {
    if (isLoading) {
      return (
        <Spin
          className="loader-spinner-lookahead-table"
          indicator={<LoadingOutlined />}
        />
      );
    }
    if (activities.length) {
      /* const array = [
                    renderGeneralHeader(),
                    ...activities
                ] */
      if (groupBy.criteria == 'no-group') {
        return (
          <CustomPaginator
            adjustHeight={193}
            totalTablesWidth={totalTablesWidth}
            massiveSelection={massiveSelection}
            resizing={resizing}
            current={currentPage}
            setCurrentPage={setCurrentPage}
            data={array}
            itemSize={height < 700 ? height * 0.55 : height * 0.68}
            renderItem={(item, key, virtualizeRef) => {
              if (key == 0) {
                paginatorRef = virtualizeRef;
                return item;
              } else if (!resizing) {
                return (
                  <CncActivity
                    t={t}
                    permission={permission}
                    updateAsyncCnc={updateAsyncCnc}
                    scrollStates={scrollStates}
                    setScrollStates={setScrollStates}
                    virtualizeRef={virtualizeRef}
                    massiveSelectionHandler={recursivelySelection}
                    setMassiveSelection={setMassiveSelection}
                    resizing={resizing}
                    projectState={projectState}
                    tableMetadata={tableConfig}
                    key={key}
                    lastLvlActivity={item}
                    index={key}
                    updateRender={updateRender}
                    cncTypes={cncTypes}
                    toSelectResponsables={toSelectResponsables}
                    handleAddItem={handleAddItem}
                    taskList={taskList}
                    setVisibleImg={setVisibleImg}
                    setImgModal={setImgModal}
                    allWeekCommitments={allWeekCommitments}
                    setDataNoGroup={setDataNoGroup}
                    dataNoGroup={dataNoGroup}
                  />
                );
              }
            }}
            perPage={1}
          />
        );
      }
      return (
        <CustomPaginator
          adjustHeight={210}
          totalTablesWidth={totalTablesWidth}
          massiveSelection={massiveSelection}
          itemSize={height < 700 ? height * 0.55 : height * 0.68}
          current={currentPage}
          setCurrentPage={setCurrentPage}
          data={array}
          renderItem={(item, key, virtualizeRef) => {
            if (key == 0) {
              paginatorRef = virtualizeRef;
              return item;
            } else if (!resizing) {
              return (
                <CncOtherGroup
                  t={t}
                  permission={permission}
                  updateAsyncCnc={updateAsyncCnc}
                  cncActions={cncActions}
                  scrollStates={scrollStates}
                  setScrollStates={setScrollStates}
                  virtualizeRef={virtualizeRef}
                  massiveSelectionHandler={recursivelySelection}
                  setMassiveSelection={setMassiveSelection}
                  resizing={resizing}
                  tableMetadata={tableConfig}
                  key={key}
                  groupParent={item}
                  index={key}
                  updateRender={updateRender}
                  cncTypes={cncTypes}
                  toSelectResponsables={toSelectResponsables}
                  handleAddItem={handleAddItem}
                  taskList={taskList}
                  setVisibleImg={setVisibleImg}
                  setImgModal={setImgModal}
                  projectState={projectState}
                  setDataNoGroup={setDataNoGroup}
                />
              );
            }
          }}
          perPage={1}
        />
      );
    }
    return (
      <Empty
        style={{ marginTop: 200 }}
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        description={<span>{t('no_find_tasks')}</span>}
      />
    );
  };

  /**
   * This function render the header with titles for the printed tables, it allows to drag n drop the position
   * as also allow to resize the width.
   */
  const renderGeneralHeader = () => {
    if (isLoading) {
      return null;
    }
    return (
      <Row className="fixed-tree-table-header">
        <AnimatedSortable
          useDragHandle
          items={tableConfig}
          onSortEnd={moveCard}
          axis="x"
          onDivResizeHandler={onDivResizeHandler}
          onDivResizeDoubleClicked={onDivResizeDoubleClicked}
        />
      </Row>
    );
  };

  /**
   * This function render modal Add
   */
  const handleAddItem = (task) => {
    setVisibleForm({ value: true, tasks: [task] });
  };

  /**
   * This function render Form Add Cnc
   */
  const renderForm = () => (
    <CncForm
      allWeekCommitments={allWeekCommitments}
      allTaskCommitments={allTaskCommitments}
      allActivitiesCommitments={allActivitiesCommitments}
      cncListRq={cncListRq}
      t={t}
      visibleForm={visibleForm.value}
      setVisibleForm={(val) => setVisibleForm({ value: val, tasks: [] })}
      getCncList={getCncList}
      setCncAdded={(data) => updateRender(data)}
      responsables={toSelectResponsables}
      setDataNoGroup={setDataNoGroup}
    />
  );

  /** this function close modal of thumbs */
  const handleCloseImg = () => {
    setVisibleImg(false);
  };

  /**
   * This function render Modal  to show Thumb
   */
  const renderModalThumb = () => (
    <Modal
      closable={true}
      title=""
      visible={visibleImg}
      centered
      bodyStyle={{ height: '50vh', overflow: 'auto' }}
      keyboard={false}
      maskClosable
      onCancel={handleCloseImg}
      width={800}
      footer={<div></div>}>
      {imgModal ? (
        <div className="div-img-modal">
          <img src={imgModal} alt="imagen" className="img-modal" />
        </div>
      ) : (
        'no image'
      )}
    </Modal>
  );

  /** this function change filter: Filter by */
  const changeFilterByState = async (valueFilterBy) => {
    setFilterBy({
      ...filterBy,
      ...valueFilterBy
    });
  };

  /** this function change state for filter Order by */
  const changeOrderByState = async (valueOrderBy) => {
    setOrderBy({
      ...orderBy,
      ...valueOrderBy
    });
  };

  /** this function change state for filter Group by */
  const changeGroupByState = async (valueGroupBy) => {
    setGroupBy({
      ...groupBy,
      ...valueGroupBy
    });
  };

  useEffect(() => {
    window.Appcues.page();
  });

  const renderExtraItemsFilter = () => (
    <Col className="weekly-plan-on-page-bar" span={3} offset={6}>
      <Button
        shape="round"
        size="small"
        className="btn-style"
        onClick={() => setVisibleForm({ value: true })}>
        <span>
          <i className="fas fa-comment-alt"></i>
        </span>
        <span>{t('create_cnc_label')}</span>
      </Button>
    </Col>
  );

  /**
   * This function render the header with filtering options for printed tables
   */
  const renderFilterHeader = () => {
    if (isLoading) {
      return null;
    }
    return (
      <Row>
        <Col>
          <LookaheadFilterHeader
            // renderExtraFilterRigth={renderExtraItemsFilter}
            defaultOrderOptions={[
              {
                name: 'no-group',
                label: t('no_group'),
                switch: false
              }
            ]}
            disableLookahead
            lookaheadActions={cncActions}
            lookaheadState={'cncState'}
            groupBy={groupBy}
            modifyGroupBy={modifyGroupBy}
            data={
              groupBy.criteria == 'no-group' ? activities : activitiesReport
            }
            changeDateState={changeDateState}
            dateRange={dateRange}
            updateTableMetadata={updateTableMetadata}
            tableMetadata={tableConfig}
            cncTypes={cncTypes}
            toSelectResponsables={toSelectResponsables}
            taskList={taskList}
            updateRender={updateRender}
          />
        </Col>
      </Row>
    );
  };

  /**
   * On click function when pressing delete
   * @param {*} res resource to delete
   */
  const handleDelete = async (cnc) => {
    mutationDelete.mutate({
      cncId: cnc.id
      // activity
    });
    // await cncService.destroy(res.id)
  };
  /**
   * This functions shows a pretty alert to user
   * @param {*} data Object { title, message, type }
   */
  const notifyMessage = (data) => {
    const alertErrorMailExists = {
      title: data.title,
      description: data.message,
      type: data.type
    };
    openNotification(alertErrorMailExists);
  };

  const quitDismissHandler = () => {
    const asyncMap = massiveSelection.map(async (selection) => {
      const reference = activities[0].tasks.find((el) => selection.id == el.id);
      activities[0].active = false;
      reference.active = false;
    });
    updateRender();
    setMassiveSelection([]);
  };

  /**
   * This function deletes tasks massively
   */
  const massiveDeleteHandler = async () => {
    /** Declare an async iteration */
    const asyncMap = massiveSelection.map(async (selection) => {
      const newChildrens = activities[0].tasks.filter(
        (res) => res.id !== selection.id
      );
      activities[0].tasks = newChildrens;
      await handleDelete(selection);
    });

    /** We await of async iteration */
    await Promise.all(asyncMap);

    /** Then update the render, notify of process, and clean the massive selection state */
    updateRender();
    notifyMessage({
      title: t('weekly_plan_cnc.title'),
      message: t('succesfully_removed_cnc'),
      type: 'success'
    });
    setMassiveSelection([]);
  };

  const massiveStatusHandle = async (option) => {
    if (groupBy.criteria == 'no-group') {
      const asyncMap = massiveSelection.map(async (selection) => {
        const reference = activities[0].tasks.find(
          (el) => selection.id == el.id
        );
        reference.status = option.value;
        updateAsyncCnc(reference);
      });
      /** We await of async iteration */
      await Promise.all(asyncMap);
      setMassiveStatusPop({ visible: false });
      updateRender();
    }
  };

  /**
   * This function handle massive change of priority
   * @param {*} option Single object with selected priority
   */
  const massivePriorityHandle = async (option) => {
    if (groupBy.criteria == 'no-group') {
      const asyncMap = massiveSelection.map(async (selection) => {
        const reference = activities[0].tasks.find(
          (el) => selection.id == el.id
        );
        reference.priority = option.value;
        updateAsyncCnc(reference);
      });
      /** We await of async iteration */
      await Promise.all(asyncMap);
      setMassivePriorityPop({ visible: false });
      updateRender();
    }
  };

  /**
   * This function handle massive change of priority
   * @param {*} option Single object with selected priority
   */
  const massiveTypeHandle = async (option) => {
    if (groupBy.criteria == 'no-group') {
      const asyncMap = massiveSelection.map(async (selection) => {
        const reference = activities[0].tasks.find(
          (el) => selection.id == el.id
        );
        reference.cncTypeId = option.id;
        updateAsyncCnc(reference);
      });
      /** We await of async iteration */
      await Promise.all(asyncMap);
      setMassiveTypePop({ visible: false });
      updateRender();
    }
  };

  /** handle popover of massive selection */
  const handleVisibleMassivePop = (visible, type) => {
    if (type === 'priority') {
      setMassivePriorityPop({ visible });
    } else if (type === 'type') {
      setMassiveTypePop({ visible });
    } else if (type === 'status') {
      setMassiveStatusPop({ visible });
    }
  };

  /**
   * This function prints the header with actions to deal with massive selection
   */
  const renderMassiveActionHeader = () => (
    <Animated
      style={{ height: massiveSelection.length ? 30 : 0, overflow: 'hidden' }}
      animationIn="fadeIn"
      animationInDuration={250}
      animationOut="fadeOut"
      animationOutDuration={250}
      isVisible={Boolean(massiveSelection.length)}>
      <Row className="massive-action-header">
        <Col span={12} offset={6} style={{ height: '100%' }}>
          <Row
            type="flex"
            justify="space-around"
            align="middle"
            style={{ height: '100%' }}>
            {massiveOnProcess ? null : (
              <Col style={{ textAlign: 'center' }}>
                {/* Selection text indicator */}
                <span style={{ color: '#7DFF8A' }}>
                  {massiveSelection.length + ' '}
                </span>
                <span style={{ color: '#FFFFFF', marginRight: 23 }}>
                  {t('cnc_selected_only')}
                  {massiveSelection.length > 1
                    ? userLang.includes('es')
                      ? 's'
                      : null
                    : null}
                </span>

                {/* Massive Type */}
                <span className="massive-icon-style">
                  <Popover
                    overlayClassName="massive-selection-pop"
                    placement="bottom"
                    content={
                      <span className="progress-massive-style">
                        {cncTypes &&
                          cncTypes.map((option, index) => (
                            <div
                              style={{ cursor: 'pointer' }}
                              key={index}
                              onClick={() => massiveTypeHandle(option)}>
                              <span style={{ marginLeft: 5 }}>
                                {capitalize(option.name)}
                              </span>
                            </div>
                          ))}
                      </span>
                    }
                    trigger="click">
                    <Tooltip placement="top" title={t('type_only_label')}>
                      <span>
                        <img
                          className="massive-icons"
                          width={12}
                          src={typeMassive}
                        />
                      </span>
                    </Tooltip>
                  </Popover>
                </span>

                {/* Massive Priority */}
                <span className="massive-icon-style">
                  <Popover
                    overlayClassName="massive-selection-pop"
                    placement="bottom"
                    content={
                      <span className="progress-massive-style">
                        {tableMetadata
                          .find((col) => col.name == 'priority')
                          .from_values.map((option, index) => (
                            <div
                              style={{ cursor: 'pointer' }}
                              key={index}
                              onClick={() => massivePriorityHandle(option)}>
                              <img
                                src={option.icon}
                                width={11}
                                style={{ position: 'relative', top: 0 }}
                              />
                              <span style={{ marginLeft: 5 }}>
                                {capitalize(option.label)}
                              </span>
                            </div>
                          ))}
                      </span>
                    }
                    trigger="click">
                    <Tooltip placement="top" title={t('priority_label_only')}>
                      <span>
                        <img
                          className="massive-icons"
                          width={12}
                          src={priorityMassive}
                        />
                      </span>
                    </Tooltip>
                  </Popover>
                </span>

                {/* Massive Status */}
                <span className="massive-icon-style">
                  <Popover
                    overlayClassName="massive-selection-pop"
                    placement="bottom"
                    content={
                      <span className="progress-massive-style">
                        {tableMetadata
                          .find((col) => col.name == 'status')
                          .from_values.map((option, index) => (
                            <div
                              style={{ cursor: 'pointer' }}
                              key={index}
                              onClick={() => massiveStatusHandle(option)}>
                              <i
                                className="fas fa-circle border-icon"
                                style={{
                                  fontSize: 11,
                                  color: option.color,
                                  position: 'relative',
                                  top: -1
                                }}
                              />
                              <span style={{ marginLeft: 5 }}>
                                {capitalize(option.label)}
                              </span>
                            </div>
                          ))}
                      </span>
                    }
                    trigger="click">
                    <Tooltip
                      placement="top"
                      title={t('status_only_label_constraint')}>
                      <span>
                        <img
                          width={12}
                          src={statusMassive}
                          className="massive-icons"
                        />
                      </span>
                    </Tooltip>
                  </Popover>
                </span>

                {/* Massive delete */}
                <Popconfirm
                  onConfirm={() => massiveDeleteHandler()}
                  title={t('are_sure_general')}
                  icon={
                    <Icon type="question-circle-o" style={{ color: 'red' }} />
                  }>
                  <Tooltip placement="top" title={t('delete_task_label')}>
                    <span className="massive-icon-style">
                      <img
                        width={12}
                        src={deleteMassive}
                        className="massive-icons-delete"
                      />
                    </span>
                  </Tooltip>
                </Popconfirm>

                {/* Quit Dismiss */}
                <Tooltip placement="top" title={t('cancel_only_label')}>
                  <span
                    className="massive-icon-style"
                    onClick={quitDismissHandler}>
                    <img
                      width={12}
                      src={quitDismissIcon}
                      style={{ position: 'relative', top: -1 }}
                    />
                  </span>
                </Tooltip>
              </Col>
            )}
          </Row>
        </Col>
      </Row>
    </Animated>
  );

  /**
   * Render
   */
  const renderWithPermission = () => {
    if (permission == 'ACP' || permission == 'AC' || permission == 'V') {
      return (
        <Animated
          animationIn="fadeIn"
          animationInDuration={500}
          isVisible={true}>
          <Row className="table-cncs">
            <Col>
              {renderFilterHeader()}
              {renderMassiveActionHeader()}
              {renderTable()}
            </Col>
          </Row>

          {/* Component Form Cncs */}
          {renderForm()}

          {/* Component Modal Thumbs */}
          {renderModalThumb()}
        </Animated>
      );
    }
    return <div>{t('non_permission_access')}</div>;
  };

  /**
   * Render
   */
  return renderWithPermission();
}

export default withTranslation()(CncView);
