import { MoreUserIcon } from '../../icons';
import colors from '../../stylesheets/variables.scss';

export const WEEKLY_PLAN_ITEMS = [
  {
    icon: MoreUserIcon,
    iconColor: colors.gray80,
    direction: 'LEFT',
    tooltipI18nKey: 'modals.add_users_modals.add_users_tooltip',
    command: 'GANTT_ADD_USERS_ITEMS',
    i18nKey: 'modals.add_users_modals.add_users_btn',
    subitems: [
      {
        iconColor: colors.greenIcon,
        i18nKey: 'modals.add_users_modals.create_new_user_option',
        command: 'OPEN_ADD_USERS_MODAL'
      },
      {
        iconColor: colors.greenIcon,
        i18nKey: 'modals.add_users_modals.assign_existing_users_option',
        command: 'OPEN_ASSIGN_USERS_MODAL'
      }
    ]
  }
];
