import React from 'react';

import { ToolbarGroup } from '../../components/ToolbarGroup';
import { WEEKLY_PLAN_ITEMS } from './constants';
import styles from '../../components/GanttSettings/GanttSettings.module.scss';

export const WeeklyplanSettings = ({ t, onCommandDispatched }) => (
  <div className={styles['gantt-settings']}>
    <ToolbarGroup
      items={WEEKLY_PLAN_ITEMS}
      onCommandDispatched={onCommandDispatched}
      t={t}
    />
  </div>
);
