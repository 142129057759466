/* eslint-disable no-eval */

/** React components  */
import React from 'react';

/** react-query */
import { useQuery } from 'react-query';

import { Spin, Icon } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

/** import helpers */
import {
  getCncTypes,
  getResponsables,
  getLastLevelActivities,
  getListCncs,
  getWeekCommitments
} from '../weeklyPlan.helper';

/** Redux */
import { useSelector } from 'react-redux';

import CncsView from '../cncs';

import { withTranslation } from 'react-i18next';
import useWeeklyplanPermissions from '../../../hooks/useWeeklyplanPermissions';

/**
 * This view shows activities from last level.
 * Then user can through a filter change range date showed.
 * Then, user can add on eagger data model tasks, associating them with the activity ID as a super parent.
 * @param {*} props
 */
function LoadCncView(props) {
  const { permission, t } = props;

  const weeklyplanPermissions = useWeeklyplanPermissions();

  /** Project state from redux */
  const projectState = useSelector((state) => state.projectState);
  const projectSelected = projectState.allProjects.find(
    (el) => el.id == projectState.projectSelected
  );

  // Queries && Mutations
  /** get users by sector */
  const cncTypesRq = useQuery(['cncTypesRq', projectSelected], () =>
    getCncTypes(projectSelected)
  );

  /** get users by sector */
  const responsablesRq = useQuery(
    ['responsablesRq', projectState.sectorSelected],
    async () => await getResponsables({ id: projectState.sectorSelected })
  );

  /** get cncs by sector */
  const cncListRq = useQuery(['cncListRq', projectState.sectorSelected], () =>
    getListCncs(projectState.sectorSelected)
  );

  /** get week commitments */
  const weekCommitmentsListRq = useQuery(
    ['weekCommitmentsListRq', projectState.sectorSelected],
    () => getWeekCommitments(projectState.sectorSelected)
  );

  /** get last level activities */
  // const lastLevelActivitiesRq = useQuery(['lastLevelActivitiesRq', projectState.sectorSelected], () => getLastLevelActivities({ id: projectState.sectorSelected }))

  /**
   * Render
   */
  const renderWithPermission = () => {
    if (permission == 'ACP' || permission == 'AC' || permission == 'V') {
      return cncTypesRq.data &&
        responsablesRq.data &&
        cncListRq.data &&
        weekCommitmentsListRq.data ? (
        <CncsView {...props} permission={weeklyplanPermissions.cncs} />
      ) : null;
    }
    return <div>{t('non_permission_access')}</div>;
  };

  /**
   * Render
   */
  return renderWithPermission();
}

export default withTranslation()(LoadCncView);
