import React, { useEffect } from 'react';
import moment from 'moment';
import { Animated } from 'react-animated-css';
import { Switch, Route, useRouteMatch, useParams } from 'react-router-dom';
import LoadCncsView from './loadcncs';
import Commitments from './Commitments';
import PlanningView from './planning';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import useWeeklyplanPermissions from '../../hooks/useWeeklyplanPermissions';
import WeeklyPlanMenu from './WeeklyplanMenu';
import './index.css';
import { withTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import * as projectActions from '../../redux/slices/projectSlice';
import { message } from 'antd';
import { TimerManagerSingleton } from '../../utils/timerManager';

function WeeklyPlanMainView(props) {
  const timerManager = TimerManagerSingleton.getInstance();
  const { height } = useWindowDimensions();
  const weeklyplanPermissions = useWeeklyplanPermissions();
  const match = useRouteMatch();
  const { t } = props;
  const { projectIdCnc, sectorIdCnc } = useParams();
  const projectState = useSelector((state) => state.projectState);
  const projectSelected = projectState.allProjects.find(
    (el) => el.id == projectState.projectSelected
  );
  const dispatch = useDispatch();

  const updatingProjectandSector = async () => {
    dispatch(projectActions.setSector(parseInt(sectorIdCnc)));
    dispatch(projectActions.setProject(parseInt(projectIdCnc)));

    const updatingProjectCallback = () => {
      dispatch(projectActions.setSector(parseInt(sectorIdCnc)));
    };
    timerManager.registerAutoTimeout(
      updatingProjectCallback,
      800,
      'updatingProjectandSector'
    );
  };
  useEffect(() => {
    if (!projectSelected) return;
    const projectBelongsToUser = projectState.allProjects.find(
      (el) => el.id == parseInt(projectIdCnc)
    );
    if (projectIdCnc && sectorIdCnc && projectBelongsToUser) {
      updatingProjectandSector();
    }
    if (projectIdCnc && sectorIdCnc && !projectBelongsToUser) {
      message.error('Access denied');
    }
  }, [projectIdCnc, sectorIdCnc]);
  return (
    <Animated
      animationIn="fadeIn"
      animationInDuration={500}
      isVisible={true}
      style={{ height: height - 72, overflow: 'auto' }}>
      <WeeklyPlanMenu project={projectSelected} history={props.history} t={t} />
      <Switch>
        {projectIdCnc && sectorIdCnc && (
          <LoadCncsView {...props} permission={weeklyplanPermissions.cncs} />
        )}
        {/* <Route path={`${match.path}/planning`}>
                    <PlanningView { ...props } permission={weeklyplanPermissions.plan}/>
                </Route> */}
        <Route path={`${match.path}/cncs`}>
          <LoadCncsView {...props} permission={weeklyplanPermissions.cncs} />
        </Route>
        <Route path={`${match.path}/commintments`}>
          <Commitments permission={weeklyplanPermissions} {...props} />
        </Route>
      </Switch>
    </Animated>
  );
}

export default withTranslation()(WeeklyPlanMainView);
