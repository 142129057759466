import React, { useEffect } from 'react';
import EventEmitter from 'react-native-eventemitter';
import General from '../analytics/general';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { getAnalitycsByProjectBack } from '../../views/weeklyPlan/weeklyPlan.helper';
import useAnalyticsPermissions from '../../hooks/useAnalyticsPermissions';
import InvalidLinkLayout from '../../layout/invalidLink';
import { NO_MODULE_ACCESS } from '../../constants/access';
import { trackingEvent } from '../../analytics';
import { getSessionTokenData } from '../../utils/userUtils';
import { roleTypes } from '../../constants/role.constants';

/** Build components from antd css framework  */
import { Spin, Icon } from 'antd';

export default function TaktAnalyticsDashboard(props) {
  /** consts */
  const permissions = useAnalyticsPermissions();
  const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;

  const userSessionStorage = getSessionTokenData();

  const allowedCompanies = [721, 306, 272, 92, 572, 204];
  const allowedRoles = [
    roleTypes.SUPERADMIN,
    roleTypes.ADMIN,
    roleTypes.PROJECTLEADER,
    roleTypes.PLANNER
  ];

  /** redux */
  const projectState = useSelector((state) => state.projectState);
  const projectSelected = projectState.allProjects.find(
    (e) => e.id === projectState.projectSelected
  );

  useEffect(() => {
    trackingEvent('takt_analytics_visualization');
  }, []);

  /** Similar to did mount */
  useEffect(() => {
    const callback = (data) => {
      data.route(props.history);
    };

    EventEmitter.on('changeMainRoute', callback);
    return () => {
      EventEmitter.removeListener('changeMainRoute', callback);
    };
  }, []);
  useEffect(() => {
    window.Appcues.page();
  });

  /** Query */
  const analitycsRq = useQuery(['analitycsRqBack', projectSelected?.id], () =>
    getAnalitycsByProjectBack(projectSelected?.id)
  );

  if (
    !userSessionStorage ||
    !allowedRoles.includes(userSessionStorage.role) ||
    !allowedCompanies.includes(parseInt(userSessionStorage.companyId))
  ) {
    return (
      <div
        style={{
          width: '100%',
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}>
        <InvalidLinkLayout type={NO_MODULE_ACCESS} />
      </div>
    );
  }

  /** render */
  return analitycsRq.data?.data ? (
    <div style={{ background: '#FFFFFF' }}>
      <General
        permissions={permissions}
        analitycsRq={analitycsRq}
        isTaktAnalyticsDashboard={true}
      />
    </div>
  ) : (
    <div className="loader-spinner-analytics">
      <Spin indicator={antIcon} className="spinner-load" />
    </div>
  );
}
