import React, { useRef, useState, useEffect, Suspense } from 'react';
import { useSelector } from 'react-redux';
import MenuSubmodules from '../../components/MenuSubmodules';
import { useParams } from 'react-router-dom';
import { WeeklyplanSettings } from './WeeklyplanSettings';
import { getSectorCompanyAndProject } from '../../analytics/utils';
import { userService } from '../../services';
import {
  mappedUserToDataSource,
  trackingEventAddUsersButton
} from '../../utils/addUsersModalsUtils';

const ModalAssignUser = React.lazy(
  () => import('../../components/Settings/ModalAssignUser')
);
const ModalAddUsers = React.lazy(
  () => import('../../components/ModalAddUsers/ModalAddUsers')
);

const WeeklyPlanMenu = (props) => {
  const { projectSelected, history, t } = props;
  const [Comp, setComp] = useState({ Comp: null, props: null });
  const [visibleAssignUser, setVisibleAssignUser] = useState(false);
  const [usersByCompany, setUsersByCompany] = useState([]);
  const [isReloadTableUsers, setIsReloadTableUsers] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [isShowAddUserModal, setIsShowAddUserModal] = useState(false);
  const [message, setMessage] = useState('');
  const { currentCompany: sessionCompany } = getSectorCompanyAndProject();
  const projectState = useSelector((state) => state.projectState);
  const { projectIdCnc, sectorIdCnc } = useParams();

  const onWeeklyplanSettingsCommandDispatched = (command) => {
    const ADD_USERS_COMMANDS = {
      OPEN_ADD_USERS_MODAL: () => setIsShowAddUserModal(true),
      OPEN_ASSIGN_USERS_MODAL: () => setVisibleAssignUser(true),
      GANTT_ADD_USERS_ITEMS: () => trackingEventAddUsersButton()
    };
    const addUsersCommand = ADD_USERS_COMMANDS[command];
    if (addUsersCommand) {
      addUsersCommand();
    }
  };

  useEffect(
    (_) => {
      setComp({
        Comp: WeeklyplanSettings,
        props: {
          t,
          onCommandDispatched: onWeeklyplanSettingsCommandDispatched,
          messageModule: t(
            messageOptions[projectSelected.task_creter.toUpperCase()]
          )
        }
      });
    },
    [projectSelected]
  );
  useEffect(() => {
    handleGetUsersProject();
  }, [projectState.projectSelected]);
  useEffect(() => {
    if (isReloadTableUsers) {
      handleGetUsersProject();
      setIsReloadTableUsers(false);
    }
  }, [isReloadTableUsers]);
  useEffect(() => {
    loadUsersByCompany();
  }, [projectState.projectSelected]);

  useEffect(() => {
    projectSelected &&
      setMessage(
        t(messageOptions[projectSelected?.task_creter?.toUpperCase()])
      );
  }, [projectSelected]);

  const { current: messageOptions } = useRef({
    DURATION: 'info_criteria_task_duration',
    COST: 'info_criteria_task_cost',
    HH: 'info_criteria_task_hh'
  });

  const loadUsersByCompany = async () => {
    if (!sessionCompany?.id) {
      return;
    }

    const usersByCompany = await userService.getByCompany(sessionCompany.id);
    setUsersByCompany(usersByCompany.users);
  };

  const handleGetUsersProject = async () => {
    try {
      const users = await userService.getUsersProjectSettings(
        projectState.projectSelected
      );
      const parsedUsers = users?.users
        .filter((user) => user.enabling_status)
        .map((user) => mappedUserToDataSource(user, t));
      setDataSource(parsedUsers);
    } catch {
      setDataSource([]);
    }
  };

  const publishSubmodules = ({ history, match, t, toggleActiveButtons }) => [
    {
      id: 1,
      title: t('tabs.weeklyplan.plan_legacy'),
      routeName: `${match.url}/planning`,
      onClick: (_) => {
        toggleActiveButtons(1);
        if (projectIdCnc || sectorIdCnc) {
          history.push('/weeklyplan/planning');
        } else {
          history.push(`${match.url}/planning`);
        }
      },
      hide: true
    },
    {
      id: 2,
      title: t('tabs.weeklyplan.plan'),
      routeName: `${match.url}/commintments`,
      onClick: (_) => {
        toggleActiveButtons(2);
        if (projectIdCnc || sectorIdCnc) {
          history.push('/weeklyplan/commintments');
        } else {
          history.push(`${match.url}/commintments`);
        }
      },
      hide: false
    },
    {
      id: 3,
      title: t('tabs.weeklyplan.cncs'),
      routeName: `${match.url}/cncs`,
      onClick: (_) => {
        toggleActiveButtons(3);
        if (projectIdCnc || sectorIdCnc) {
          history.push('/weeklyplan/cncs');
          return;
        }
        history.push(`${match.url}/cncs`);
      },
      hide: false
    }
  ];

  return (
    <div>
      <MenuSubmodules
        publishSubmodules={publishSubmodules}
        history={history}
        messageModule={message}
        CustomRightComp={Comp}
      />
      <Suspense
        fallback={
          <div>{t('settings.project_settings.users_tab.suspense_loading')}</div>
        }>
        <ModalAssignUser
          visible={visibleAssignUser}
          setVisible={setVisibleAssignUser}
          setReloadTableUsers={setIsReloadTableUsers}
          reloadTableUsers={isReloadTableUsers}
          usersByCompany={usersByCompany}
          usersProject={dataSource}
        />
      </Suspense>
      <Suspense
        fallback={
          <div>{t('settings.project_settings.users_tab.suspense_loading')}</div>
        }>
        <ModalAddUsers
          isShow={isShowAddUserModal}
          setIsShow={setIsShowAddUserModal}
          projectAssigned={[{ value: projectState.projectSelected }]}
          setReloadTableUsers={setIsReloadTableUsers}
        />
      </Suspense>
    </div>
  );
};

export default WeeklyPlanMenu;
